.featureBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  font-family: Roboto;

  & > .featureInfoBox {
    width: 36%;
    flex-basis: 480px;
    margin-right: 90px;

    & > .title {
      display: inline-block;
      font-weight: 700;
      color: #1a1b1c;
      font-size: 50px;
      text-align: left;
      line-height: 64px;
    }

    & > .descBox {
      width: 100%;
      padding: 20px 0 0 0;
      font-size: 17px;
      font-weight: 400;
      color: #192033;
      text-align: left;
      line-height: 26px;

      & > a {
        color: #ff822c;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
          color: #e0640f;
        }
      }
    }

    & > .featureList {
      list-style: none;
      margin: 30px 0 0 0;

      & > li {
        display: flex;
        align-items: center;
        margin-top: 22px;

        &:first-child {
          margin-top: 0;
        }

        .featureItemIcon {
          width: auto;
          max-width: 64px;
          max-height: 64px;
          object-fit: contain;
          margin-right: 16px;
        }

        & > p {
          flex: 1;
          font-size: 18px;
          text-align: left;
          color: #080a10;
          line-height: 26px;
        }
      }
    }

    .btn {
      min-width: 190px;
      height: 50px;
      display: inline-block;
      padding: 0 40px;
      white-space: nowrap;
      margin: 30px 0 0 0;
      background: #ff822c;
      //box-shadow: 0 10px 20px 0 rgba(255, 130, 44, 0.15);
      border-radius: 30px;
      color: #ffffff;
      text-align: center;
      line-height: 50px;
      cursor: pointer;
      transition: all 0.3s;
      font-weight: 500;

      &:hover {
        background: #e7650c;
        box-shadow: none;
      }
    }
  }

  & > .featureInfoBoxReverse {
    margin-right: 0;
    margin-left: 90px;
  }

  & > .featureImgBox {
    width: 54%;

    img {
      width: 100%;
      height: auto;
      border-radius: inherit;
      box-shadow: 0 10px 60px 0 rgba(0,0,0,0.06);
    }
  }
}

.boxReverse {
  flex-direction: row-reverse;
}

.useAnimationClass {
  opacity: 0;
}

@media screen and (max-width: 1100px) {
  .featureBox {
    flex-direction: column-reverse;
    & > .featureInfoBox {
      width: 100% !important;
      margin-right: 0;
      flex-basis: auto;
    }

    & > .featureInfoBoxReverse {
      margin-right: 0;
      margin-left: 0;
    }

    .featureImgBox {
      width: 100%!important;
      margin-bottom: 30px;
    }
  }
  .boxReverse {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 500px) {

  .featureBox {
    .featureImgBox {
      width: auto !important;
      margin-bottom: 0;
    }

    .featureInfoBox {
      width: 100%;
      margin-top: 0;

      & > .featureList {
        margin: 15px 0 0 0;

        & > li {
          margin-top: 14px;

          .featureItemIcon {
            width: 50px;
            height: 50px;
            margin-right: 12px;
          }
        }
      }

      .btn {
        height: 42px;
        line-height: 42px;
        padding: 0 26px;
        min-width: auto;
        margin: 20px 0;
        font-size: 15px;
        font-weight: 500;
      }
    }
  }

  .box_margin {
    &:first-child {
      margin-top: 40px;
    }
  }
}